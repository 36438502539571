 <template>
	<el-dialog title="添加平台银行卡" top="1vh" width="600px" :visible.sync="is_show_in_page">
		<el-form label-position="left" label-width="75px">
			
			<el-form-item label="银行名称">
				<el-input v-model="form.bank_cname" placeholder="请输入银行名称(全称)" clearable></el-input>
			</el-form-item>

			<el-form-item label="银行卡号">
				<el-input v-model="form.card_num" placeholder="请输入银行卡号" clearable></el-input>
			</el-form-item>
			
			<el-form-item label="用于出金">
				<el-select v-model="form.use_for_outcash" style="width:100%" clearable>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="用于收款">
				<el-select v-model="form.use_for_collection" style="width:100%" clearable>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="备注">
				<el-input v-model="form.mark" placeholder="请输入线路备注" clearable></el-input>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="sub">提交</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//表单
				form: {

					//银行中文名
					bank_cname:'',

					//银行卡号
					card_num:'',

					//是否用来出金(1:是,2:不是)
					use_for_outcash:"1",

					//是否用来收款(1:是,2:不是)
					use_for_collection:"1",

					//备注
					mark:'',
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		methods: {
			
			//提交
			sub(){

				if(!this.form.bank_cname.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"银行名称为空,请输入"
					});
					return;
				}

				if(!this.$my.check.is_bank_card(this.form.card_num)){
					this.$my.other.msg({
						type:'warning',
						str:"银行卡号为空格式有误"
					});
					return;
				}

				//调用接口
				this.$my.net.req({
					data:{
						mod:'finance',
						ctr:'platform_bankcard_add_admin',
						bank_cname:this.form.bank_cname.trim(),
						card_num:this.form.card_num,
						use_for_outcash:this.form.use_for_outcash,
						use_for_collection:this.form.use_for_collection,
						mark:this.form.mark,
					},
					callback:(data)=>{

						//报告结果
						this.$my.other.msg({
							type:'success',
							str:"添加成功"
						});

						//关闭页面
						this.is_show_in_page=false;

						//通知
						this.$emit("added");
					}
				});
			},
		}
	}
</script>